@import "core";
.section.highlightedevents {
	position: relative;
	background-color: $color-primary;
	color: $color-white;
	margin-bottom: 1.2rem;

	@include media {
		margin-bottom: 0;
	}
	
	.highlightedevents-bgwrap {
		background-color: $color-primary;
		position: absolute;
		top: -11.7rem;
		left: 0;
		width: 100%;
		bottom: 0;

		@include media {
			top: calc((8.6rem + var(--safe-area-insets-top, env(safe-area-inset-top, 2rem))) * -1);
		}
	}

		.bgwrap-image {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-position: top center;
		}
	
	.highlightedevents-wrap {
		position: relative;
		z-index: 1;
		padding: 0 0 2.2rem;
		
		@include mediaMin {
			padding: 3.1rem 0 4.8rem;
			border-top: .1rem solid rgba($color-white, .3);
		}

		&:after {
			@include pseudo;
			position: absolute;
			top: 100%;
			left: 50%;
			transform: translate3d(-50%, 0, 0);
			border: 1.2rem solid transparent;
			border-left-width: 1.8rem;
			border-right-width: 1.8rem;
			border-top-color: $color-primary;

			@include media {
				display: none;
			}
		}
	}

	.highlightedevents-heading {
		@include font-bold;
		font-size: 12rem;
		line-height: 8.8rem;
		position: absolute;
		bottom: 0;
		right: 0;
		z-index: 1;
		opacity: .1;
	}

	.highlightedevents-list {
		position: relative;
		z-index: 2;
		max-width: 91.6rem;
		display: grid;
		gap: 3rem;
		grid-template-columns: 2fr 1fr 1fr;

		grid-template-areas: 'highlight item1 item2'
			'highlight item3 item4';

		@include media {
			grid-template-columns: 1fr 1fr;
			gap: 1rem;

			grid-template-areas: 'highlight highlight'
			'item1 item2'
			'item3 item4';
		}
	}

		.list-item {
			display: flex;
			min-height: 28.9rem;
			border-radius: $radius-general;
			background-color: $color-text;
			overflow: hidden;
			position: relative;

			@include media {
				min-height: 21rem;
			}

			&.highlight {
				grid-area: highlight;

				@include mediaMin {
					margin-right: -1rem;
				}

				@include media {
					min-height: 43.1rem;
				}
				
				.item-bottom {
					@include media {
						padding: 2rem 2rem 2.5rem;
					}
				}

				.item-title {
					font-size: 4rem;

					@include media {
						font-size: 3rem;
					}
				}

				.item-controls {
					margin-top: 2.8rem;
					justify-content: flex-start;

					@include media {
						margin-top: 2.4rem;
					}
				}

				.item-datetime {
					width: 5.9rem;
					font-size: 1.4rem;

					@include media {
						width: 4.8rem;
					}
				}

					.datetime-day {
						font-size: 2.6rem;
						line-height: 3.9rem;

						@include media {
							font-size: 2rem;
							line-height: 3.1rem;
						}
					}

					.datetime-month {
						font-size: 1.8rem;
						line-height: 2rem;

						@include media {
							font-size: 1.4rem;
							line-height: 2rem;
						}
					}

					.datetime-time {
						line-height: 2.4rem;

						@include media {
							font-size: 1.2rem;
							line-height: 1.5rem;
						}
					}
			}
		}

			.item-link {
				display: block;
				width: 100%;
				position: relative;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}

			.item-top {
				display: flex;
				padding: .7rem;
				justify-content: flex-end;
				position: relative;
				z-index: 2;
			}

			.item-bottom {
				background: transparent linear-gradient(180deg, rgba($color-black, 0) 0%, rgba($color-black, 1) 100%) 0% 0% no-repeat padding-box;
				height: 70%;
				padding: 2rem;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				align-items: flex-start;
				position: relative;
				z-index: 2;

				@include media {
					padding: 1rem;
				}
			}

			.item-datetime {
				border: .1rem solid $color-white;
				background-color: $color-white;
				border-radius: $radius-small;
				color: $color-text;
				text-align: center;
				display: flex;
				flex-direction: column;
				width: 4.8rem;
				padding: .1rem 0 .2rem;
				overflow: hidden;
				font-size: 1.2rem;

				@include media {
					width: 3.8rem;
				}
			}

				.datetime-day {
					@include font-bold;
					background-color: $color-red;
					color: $color-white;
					font-size: 2.2rem;
					line-height: 3.4rem;

					@include media {
						font-size: 1.8rem;
						line-height: 2.7rem;
					}
				}
				
				.datetime-month {
					@include font-bold;
					font-size: 1.4rem;
					line-height: 2rem;

					@include media {
						font-size: 1.2rem;
						line-height: 1.8rem;
					}
				}

				.datetime-time {
					line-height: 2rem;

					@include media {
						font-size: 1rem;
						line-height: 1.4rem;
					}
				}

			.item-image {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}

			.item-title {
				@include font-bold;
				font-size: 2rem;
				display: block;

				@include media {
					font-size: 1.4rem;
				}
			}

			.item-features {
				margin-top: 1.1rem;

				@include media {
					margin-top: 0;
				}
			}

				.features-feature {
					background-color: rgba($color-primary, .55);
					border-color: transparent;
					margin-left: 0;

					&:not(:last-child) {
						margin-right: .5rem;
					}

					@include media {
						margin-top: 1rem;
					}
				}

			.item-controls {
				margin-top: 1.1rem;
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;
			}

			.item-buybtn {
				width: 8.3rem;

				&.big {
					width: 15.3rem;
				}
			}

			.item-detailbtn {
				margin-left: 1.5rem;

				&.big {
					margin-left: 2.9rem;

					@include media {
						font-size: 1.4rem;
						margin-left: 2.2rem;
					}
				}

				@include media {
					font-size: 1.1rem;
					margin-left: 0;
				}
			}
	
}